.explore-container {
  padding: 80px 200px;
}
.explore-container .title {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.explore-buttons {
  display: flex;
  gap: 10px;
}
.explore-buttons .left,
.explore-buttons .right {
  background-color: #000;
  font-size: 35px;
  color: #fff;
  cursor: pointer;
}
.explore-locations {
  margin-top: 10px;
}
.explore-loc {
  cursor: pointer;
  height: 300px;
  border-radius: 10px;
  overflow: hidden;
  padding: 8px;
}
.explore-loc .image {
  position: relative;
  height: 100%;
  border-radius: 10px;
  overflow: hidden;
}
.explore-loc .image::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  z-index: 10;
}
.explore-loc img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 5px;
  cursor: pointer;
  scale: 1;
  transition: 0.3s all ease;
  &:hover {
    scale: 1.025;
  }
}
.explore-loc .circle {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  z-index: 12;
  color: #fff;
  border: 2px dashed #fff;
  border-radius: 100px;
  height: 120px;
  width: 120px;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(255, 255, 255, 0.15);
}
.explore-container .slick-slider {
}

@media screen and (max-width: 992px) {
  .explore-container {
    padding: 50px 20px;
  }
  .explore-loc img {
    height: 300px;
    object-fit: cover;
  }
}
@media screen and (max-width: 600px) {
  .explore-container {
    padding: 20px;
  }
  .explore-loc {
    height: 200px;
  }
  .explore-loc img {
    height: 200px;
  }
}
