.banner-container {
  margin-bottom: 20px;
}
.admin-promo-banner button {
  background-color: #ffca00;
  padding: 8px 25px;
  outline: transparent;
  border: transparent;
  border-radius: 5px;
  &:hover {
    opacity: 0.8;
  }
}

.banner-container img {
  width: 30%;
  display: block;
  margin-bottom: 10px;
}
