.home-videos {
  padding: 50px 300px;
  overflow: hidden;
}
.home-videos .cursive {
  font-size: 80px;
  color: #000;
  text-align: center;
  font-weight: 500;
}
.home-videos .cursive span {
  color: #333;
}
.h-videos {
  padding: 30px;
  border: 4px solid #000;
  border-radius: 15px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.3);
  position: relative;
}
.h-videos iframe {
  width: 100%;
  height: 500px;
  border-radius: 15px;
}
.h-videos .left,
.h-videos .right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  font-size: 50px;
  border: 2px solid #000;
  border-radius: 100px;
  cursor: pointer;
  transition: 0.5s all ease;
  &:hover {
    background-color: #000;
    color: #fff;
  }
}
.h-videos .left {
  left: -8%;
}
.h-videos .right {
  right: -8%;
}
.h-videos .slick-prev,
.h-videos .slick-next {
  display: none !important;
}

@media screen and (max-width: 786px) {
  .home-videos {
    padding: 0px 20px;
    padding-bottom: 100px;
  }
  .home-videos .cursive {
    font-size: 40px;
  }
  .h-videos {
    padding: 10px;
    padding-bottom: 25px;
  }
  .h-videos iframe {
    width: 100%;
    height: 350px;
  }
  .h-videos .left,
  .h-videos .right {
    top: 105%;
    transform: translateY(0);
    font-size: 40px;
  }
  .h-videos .left {
    left: 25%;
  }
  .h-videos .right {
    right: 25%;
  }
}
@media screen and (max-width: 700px) {
  .h-videos iframe {
    width: 100%;
    height: 280px;
  }
}
@media screen and (max-width: 600px) {
  .h-videos iframe {
    width: 100%;
    height: 200px;
  }
}
