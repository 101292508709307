.hero-container {
  padding: 0;
  overflow: hidden;
  position: relative;
  width: 100%;
  height: 70vh;
}
.hero-container video {
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100vh;
  object-fit: cover;
  transform: translate(-50%, -50%);
  padding: 0;
  margin: 0;
  z-index: 1;
}
.hero-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 10;
}
.hero-container .hero-title {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  font-weight: bold;
  font-size: 40px;
  z-index: 10;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 10px;
  width: 100%;
}
.hero-container .cursive h3 {
  font-size: 60px;
  font-weight: 500;
  font-family: "myFont";
}
.hero-container span {
  font-size: 20px;
  font-weight: 400;
  font-family: "Playfair Display", serif;
  color: var(--b);
}

@media screen and (max-width: 786px) {
  .hero-container {
    height: 30vh;
  }
  .hero-container video {
    height: 55vh !important;
  }
  .hero-container .hero-title {
    width: 90%;
  }
  .hero-container .cursive h3 {
    font-size: 35px;
  }
  .hero-container span {
    font-size: 15px;
  }
}
