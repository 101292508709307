.ratings-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: nowrap;
  padding: 15px;
  background-color: var(--c);
  gap: 30px;
}
.ratings {
  display: flex;
  justify-content: flex-start;
  gap: 10px;
}
.ratings .img img {
  width: 40px;
}
.ratings .content {
  text-align: start;
  max-width: fit-content;
  span {
    font-weight: 500;
  }
}
.ratings .content p {
  margin: 0;
  font-size: 14px;
}
.ratings .content .icon {
  color: gold;
  font-size: 20px;
}

@media screen and (max-width: 992px) {
  .ratings .img img {
    width: 30px;
  }
  .ratings .content p {
    font-size: 10px;
  }
}
@media screen and (max-width: 786px) {
  .ratings-container {
    gap: 10px;
  }
  .ratings {
    width: 33%;
    gap: 0;
  }
  .ratings .content span {
    font-size: 12px;
  }
  .ratings .content p {
    font-size: 10px;
  }
}
