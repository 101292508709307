.testimonial-container {
  overflow: hidden;
  padding: 50px 300px;
  position: relative;
}
.testimonial-slider-btn .test-slider-left {
  position: absolute;
  top: 50%;
  left: 10%;
  transform: translateY(-50%);
}
.testimonial-slider-btn .test-slider-right {
  position: absolute;
  top: 50%;
  right: 10%;
  transform: translateY(-50%);
}
.testimonial-slider-btn .icon {
  border: 2px solid #000;
  color: var(--two);
  font-size: 80px;
  padding: 10px;
  border-radius: 100px;
  cursor: pointer;
  transition: 0.5s all ease;
}
.testimonial-slider-btn .icon:hover {
  background-color: #000;
  color: #fff;
}
.testimonial-slider-btn p {
  font-size: 16px;
}
@media screen and (max-width: 768px) {
  .testimonial-container {
    padding: 30px 20px;
  }
  .testimonial-slider-btn .test-slider-left {
    position: absolute;
    top: 80%;
    left: 10%;
    transform: translateY(-50%);
  }
  .testimonial-slider-btn .test-slider-right {
    position: absolute;
    top: 80%;
    right: 10%;
    transform: translateY(-50%);
  }
  .testimonial-slider-btn .icon {
    font-size: 50px;
    padding: 5px;
  }
  .testimonial-container p {
    font-size: 15px;
    text-align: center;
  }
}
