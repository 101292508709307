.about-container {
  background: url("../img/about/about.jpg");
  height: 75vh;
  background-position: center;
  background-size: cover;
}
.about-container .bg-overlay {
  background-color: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.topfixed {
  position: fixed;
  top: 0px;
  left: 0;
  width: 100%;
  z-index: 99;
  transition: 0.5s all ease;
  border: 1px solid rgba(255, 255, 255, 0.2);
}
.about-tabs {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px;
  gap: 30px;
  background-color: var(--a);
  transition: 0.5s all ease;
}
.about-tabs h2 {
  font-size: 20px;
  cursor: pointer;
  color: #666;
}
.about-tabs h2.active {
  color: #000;
}
/* ============================= */
/* ============================= */
/* ============================= */

.values-and-mission {
  display: flex;
}
.values-and-mission .left,
.values-and-mission .right {
  width: 50%;
  padding: 50px;
  height: 80vh;
  display: flex;
  justify-content: center;
  flex-direction: column;
}
.values-and-mission .left {
  background-color: var(--a);
  color: #666;
}
.values-and-mission .left h2 {
  color: #000;
}
.values-and-mission .right {
  background: url("../img/about/values.jpg");
  background-position: right;
  background-size: cover;
}

/* ============================= */
/* ============================= */
/* ============================= */

.why-us {
  padding: 100px;
  text-align: center;
  position: relative;
}
.why-us h1 {
  font-size: 60px;
  margin-bottom: 10px;
}
.why-us h2 {
  font-size: 25px;
  margin-bottom: 10px;
}
.why-us p {
  padding: 0 200px;
}
/* .why-us .slick-slider {
  margin: 50px 0;
} */
.why-us-feature {
  padding: 20px;
}
.feature {
  min-height: 300px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  border-radius: 15px;
  padding: 20px;
}
.feature img {
  width: 50px;
  display: block;
  margin: auto;
}
.feature .icon {
  border: 1px solid #000;
  display: inline-block;
  padding: 20px;
  border-radius: 100px;
  margin-bottom: 20px;
}
.hero-title {
  font-size: 80px;
}

@media screen and (max-width: 1025px) {
  .about-tabs h2 {
    font-size: 14px;
  }
}
@media screen and (max-width: 786px) {
  .about-container {
    height: 55vh;
  }
  .hero-title {
    font-size: 50px;
  }
  .about-tabs {
    display: none;
  }
  .values-and-mission {
    flex-wrap: wrap;
  }

  /* =================== */
  /* =================== */
  /* =================== */
  .our-story {
    padding: 30px 10px;
  }
  /* =================== */
  /* =================== */
  /* =================== */

  .values-and-mission .left,
  .values-and-mission .right {
    padding: 50px 20px;
    height: auto;
    width: 100%;
  }
  .values-and-mission .right {
    background-position: center;
    height: 300px;
  }

  /* =================== */
  /* =================== */
  /* =================== */

  .why-us {
    padding: 30px 20px;
    padding-bottom: 50px;
  }

  .why-us-feature {
    padding: 0px;
  }

  .why-us h1 {
    font-size: 40px;
  }
  .why-us h2 {
    font-size: 15px;
    margin-bottom: 10px;
    text-align: justify;
  }
  .why-us p {
    padding: 0;
    font-size: 14px;
    text-align: justify;
  }
}
