.explore-form .form-fields {
  margin-bottom: 20px;
}
.explore-form button {
  background-color: #ffca00;
}
.explore-list img {
  width: 50px;
  height: 50px;
  object-fit: cover;
  border-radius: 100px;
}
