.login-container {
  background-size: cover;
  background-position: center;
  background: linear-gradient(135deg, #000, #333);
  padding: 100px 20px !important;
}

.login-container .form {
  background-color: #fff;
  padding: 30px;
  border-radius: 15px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.5);
}
