.sidemenu-container {
  position: fixed;
  top: 0;
  left: -100%;
  width: 60%;
  height: 100vh;
  z-index: 999;
  opacity: 0;
  transition: all 0.5s ease;
}
.sidemenu-container.active {
  left: 0;
  opacity: 1;
}
.sidemenu .close-icon {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 40px;
  color: #fff;
  cursor: pointer;
}
.sidemenu {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  background-color: #fff;
  padding: 20px;
}
.sidemenu ul {
  display: flex;
  flex-direction: column;
  width: 100%;
  padding: 0;
}
.sidemenu ul li {
  font-size: 12px;
  color: #000;
  margin: 8px 0;
  width: 100%;
  padding-bottom: 8px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.2);
  cursor: pointer;
}
.bottom-social {
  margin-top: 10px;
  width: 100%;
}
.bottom-social a {
  color: var(--a);
}
.bottom-social .icon {
  color: #000;
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding: 5px;
  font-size: 35px;
  border-radius: 100px;
  margin-right: 10px;
  transition: 0.5s all ease;
}
.bottom-social .icon:hover {
  background-color: #000;
  color: #fff;
}
.contactbtn {
  background-color: var(--b);
  width: 100%;
  outline: transparent;
  border: transparent;
  padding: 5px 20px;
  margin-top: 10px;
  border-radius: 5px;
}
