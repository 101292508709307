.package-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
}
.select-package {
  margin-bottom: 20px;
}
.add-package {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}
.add-package .form-fields {
  width: 100%;
}
.add-package .title {
  width: 100%;
}
.add-package .package {
  display: flex;
  justify-content: center;
  align-items: center;
}
.package-container .packages {
  padding-bottom: 100px;
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
}
.package-container .packages h4 {
  width: 100%;
}
.package-container .packages .package-item {
  width: 30%;
  padding: 20px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  border-radius: 20px;
  margin-bottom: 20px;
}
.package-container .packages .package-item img {
  width: 100%;
  border-radius: 20px;
  margin-bottom: 10px;
}
.package-container .packages .package-item p {
  margin: 0;
}
.package-container .packages .package-item button {
  margin-top: 20px;
}
.packagenamelist .packname {
  display: flex;
  align-items: center;
  gap: 10px;
  padding: 5px;
  background-color: var(--b);
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.3);
  padding-left: 10px;
  margin-bottom: 5px;
}
.packagenamelist .packname .icon {
  color: red;
  cursor: pointer;
}
