.mobile-packages-container {
  padding: 20px;
  overflow-x: scroll;
}
.mobile-packages-container .lineone {
  width: 100%;
  display: flex;
  overflow-x: scroll;
  gap: 20px;
}
.mobile-location-item .item-image {
  width: 70px;
  height: 70px;
}
.mobile-location-item .item-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 100%;
}
.mobile-location-item .item-name {
  text-align: center;
  font-size: 14px;
  margin-top: 5px;
}
