.dest-container {
  background-position: center !important;
  background-size: cover !important;
}
.dest-container .bg-overlay {
  height: 100%;
  padding: 100px;
  background: linear-gradient(
    to top,
    rgba(0, 0, 0, 1),
    rgba(0, 0, 0, 0.5),
    rgba(255, 255, 255, 0.1)
  );
}
.formandtitle {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}
.formandtitle .title {
  width: 65%;
}
.formandtitle .cform {
  width: 30%;
}
.formandtitle .title h4 {
  font-size: 50px;
  color: #fff;
  border-left: 10px solid #000;
  padding-left: 10px;
}
.formandtitle .title p {
  background-color: #000;
  color: #fff;
  max-width: fit-content;
  border-radius: 4px;
  font-size: 20px;
  padding: 5px;
}
.formandtitle .reviews {
  width: 100%;
}
.overview-container {
  padding: 50px 100px;
}
.overview-container p {
  text-align: justify;
  white-space: pre-wrap;
}
.cform {
  background-color: #fff;
  padding: 25px;
  border: 1px solid #000;
  border-radius: 10px;
}
.cform p {
  font: 15px;
  color: #696969;
}
.cform span {
  color: #000;
  text-decoration: underline !important;
}
.cform .form-fields {
  margin-bottom: 15px;
}
.cform .form-fields label {
  color: #000;
  margin-bottom: 5px;
}
.cform .form-fields input {
  border-radius: 5px;
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
}
.cform .form-fields input::placeholder {
  color: #b2b2b2;
}
.cform button {
  background-color: #000;
  color: #fff;
  width: 100%;
  padding: 12px;
  border: transparent;
  outline: transparent;
  border-radius: 100px;
}
.formandtitle .reviews {
  display: flex;
  gap: 100px;
}
.triprating {
  display: flex;
  gap: 10px;
}
.triprating img {
  width: 40px;
}
.triprating .content {
  text-align: start;
  min-width: fit-content;
  color: #fff;
}
.triprating .content .icon {
  color: var(--b);
}

/* GALLERY  */
.gallery-container {
  width: 100%;
  overflow: hidden;
  position: relative;
}
.gallery-container img {
  width: 100%;
  height: 500px;
  object-fit: cover;
}
.gallery-container .icon {
  top: 50%;
  position: absolute;
  transform: translateY(-50%);
  font-size: 100px;
  color: #fff;
  border: 1px solid #fff;
  border-radius: 100px;
  cursor: pointer;
  transition: 0.5s all ease;
}
.gallery-container .icon:hover {
  background-color: #fff;
  color: #000;
}
.gallery-container .gla {
  left: 30px;
}
.gallery-container .gra {
  right: 30px;
}

/* USEFUL INFORMATION */
.useful-info-container {
  padding: 50px;
  text-align: center;
  font-size: 20px;
}
.useful-info {
  position: fixed;
  top: -100%;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 9999;
  background-color: var(--three);
  padding: 20px;
  overflow-y: scroll;
  transition: 0.5s all ease;
}
.useful-info.active {
  top: 0;
}
.useful-info .icon {
  position: absolute;
  top: 20px;
  right: 20px;
  font-size: 50px;
  cursor: pointer;
  transition: 0.5s all ease;
  &:hover {
    scale: 1.2;
  }
}
.useful {
  display: flex;
  flex-wrap: wrap;
  margin-top: 30px;
  justify-content: space-between;
}
.useful-info h1 {
  width: 100%;
}
.useful-info .description {
  width: 48%;
  font-size: 15px;
  text-align: start;
  padding: 20px;
  margin: 10px;
  border-radius: 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #fff;
}
.useful-info-container button {
  padding: 20px 50px;
}

/*! ITINERARY  */
.itinerary {
  padding: 100px 200px;
  background-color: var(--three);
}
.accordion-item-day {
  background-color: #eee !important;
  border-top-left-radius: 10px;
  border-bottom-left-radius: 10px;
  padding: 20px 10px !important;
  font-weight: bold;
  font-size: 14px;
  white-space: nowrap;
  height: auto;
  width: 70px;
}
.itinerary-item {
  margin-bottom: 10px !important;
  background-color: transparent;
}
.itinerary-item button:focus {
  border: 0px !important;
  color: #000;
}
.itinerary .accordion-item-div {
  background-color: #fff;
  border-radius: 10px;
}
.itinerary .accordion-body {
  background-color: #fff;
}
.accordion-item-div span {
  font-weight: 600;
  font-size: 15px;
}

/* ABOUT  */
.tourdetails {
  padding: 80px 200px;
}
.tourabout {
  padding: 30px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  border-radius: 5px;
}
.tourabout h4 {
  font-weight: 600;
  border-left: 5px solid #000;
  padding-left: 10px;
  color: #000;
}
.tourabout p {
  font-size: 15px;
  color: #696969;
}
.tourpackages {
  margin-top: 70px;
}
.packagecontainer {
  display: flex;
  flex-wrap: wrap;
  column-gap: 20px;
  row-gap: 20px;
}
.packagecontainer h4 {
  font-size: 35px;
  font-weight: 600;
  color: #000;
  width: 100%;
  margin: 0;
}
.packagecontainer .package {
  width: 30%;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
  padding: 5px;
  border-radius: 10px;
  cursor: pointer;
}
.packagecontainer .package img {
  width: 100%;
  border-radius: 10px;
  height: 200px;
  object-fit: cover;
}
.packagecontainer .package .packcontent {
  padding: 15px;
}
.packagecontainer .package .packcontent p {
  color: #000;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 10px;
  min-height: 50px;
}
.packagecontainer .package .packcontent .timelocation {
  display: flex;
  justify-content: space-between;
  span {
    font-size: 12px;
  }
  .icon {
    color: var(--a);
  }
}
.packagecontainer .package .packcontent .timelocation span {
  border: 1px solid var(--a);
  padding: 2px 5px;
  border-radius: 100px;
  background-color: #eee;
}
.packagecontainer .package .packcontent .timelocation .icon {
  color: #000;
}

/* ACTIVITY */
.touractivities .packagecontainer {
  margin-top: 35px;
}
.touractivities .packagecontainer .package p {
  margin-bottom: 0;
}
.touractivities .packagecontainer .package .description {
  min-height: 70px;
  font-size: 12px;
}
.touractivities .packagecontainer .package .timelocation {
  margin-top: 20px;
}

/* FAQS  */
.faq-container {
  text-align: center;
}
.faq-container h4 {
  font-size: 35px;
}

/* =====  MEDIA QUERY*/
@media screen and (max-width: 992px) {
  .dest-container .bg-overlay {
    padding: 50px 20px;
  }
  .formandtitle .title {
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .formandtitle .title h4 {
    font-size: 30px;
  }
  .formandtitle .title p {
    font-size: 20px;
  }
  .formandtitle .reviews {
    justify-content: space-around;
    margin-top: 250px;
    gap: 0;
  }
  .triprating {
    flex-direction: column;
    text-align: center;
  }
  .triprating img {
    width: 40px;
  }
  .triprating .content {
    color: #fff;
    text-align: center;
    min-width: fit-content;
    p {
      font-size: 16px;
    }
  }
  .triprating .content .icon {
    color: var(--b);
  }
  .tourdetails {
    padding: 50px 20px;
  }
}
@media screen and (max-width: 786px) {
  .formandtitle .title h4 {
    font-size: 25px;
  }
  .formandtitle .title p {
    font-size: 18px;
  }
  .triprating img {
    width: 35px;
  }
  .triprating .content {
    p {
      font-size: 15px;
    }
  }
  .formandtitle .cform {
    width: 100%;
  }
  .packagecontainer .package {
    width: 100%;
  }
  .packagecontainer h4 {
    font-size: 20px;
  }
  .tourabout {
    padding: 20px;
  }
  .tourabout h4 {
    font-size: 16px;
  }
  .tourabout p {
    font-size: 12px;
  }
}
@media screen and (max-width: 600px) {
  .formandtitle .title {
    width: 100%;
    display: block;
  }
  .formandtitle .title h4 {
    font-size: 16px;
  }
  .formandtitle .title p {
    font-size: 14px;
  }
  .formandtitle .reviews {
    justify-content: space-around;
    margin-top: 250px;
    gap: 0;
  }
  .triprating {
    flex-direction: column;
    text-align: center;
  }
  .triprating img {
    width: 25px;
  }
  .triprating .content {
    color: #fff;
    text-align: center;
    min-width: fit-content;
    p {
      font-size: 10px;
    }
  }
  .triprating .content .icon {
    color: var(--b);
  }
  .packagecontainer .package .packcontent p {
    min-height: auto;
    margin-bottom: 12px;
  }
  .touractivities .packagecontainer .package .description {
    min-height: auto;
  }
}
