.footer-container {
  padding: 50px 100px;
  overflow: hidden;
  border-top: 1px solid rgba(255, 255, 255, 0.2);
  background-color: var(--a);
  color: #000;
  position: relative;
}
.footerlogo .logo {
  font-size: 20px;
  font-weight: bold;
  font-size: 40px;
  color: #000;
}
.footerlogo .footer-contact {
  margin-bottom: 6px;
  .icon {
    color: #000;
    margin-right: 5px;
  }
}
.add {
  display: flex;
  flex-direction: column;
}

.quick-links h6 {
  margin-bottom: 20px;
  font-size: 15px;
  color: #000;
}
.footer-container ul li {
  font-size: 14px;
  color: #000;
  position: relative;
  cursor: pointer;
  margin: 10px 0;
}
.footer-container ul li:hover {
  color: var(--three);
}
.footer-container .social-media-links {
  display: flex;
  gap: 10px;
}
.social-media-links a {
  color: #000;
}
.social-media-links .icon {
  font-size: 30px;
  scale: 1;
  transition: 0.5s all ease;
}
.social-media-links .icon:hover {
  color: var(--three);
  scale: 1.1;
}
.footer-container span {
  color: #000;
}
.whatspp-icon {
  position: fixed;
  right: -100px;
  bottom: 30px;
  transition: 0.5s all ease;
}
.whatspp-icon.active {
  position: fixed;
  right: 30px;
  bottom: 30px;
}
.whatspp-icon .icon {
  font-size: 80px;
  color: #fff;
  background-color: green;
  border-radius: 100px;
  padding: 10px;
  z-index: 99999;
}

@media screen and (max-width: 992px) {
  .footer-container {
    padding: 50px 20px;
  }
}
@media screen and (max-width: 768px) {
  .imglogo {
    width: 300px;
    margin-bottom: 20px;
  }
  .footer-container {
    padding: 50px 20px;
  }
  .whatspp-icon {
    right: -100px;
    bottom: 20px;
  }
  .whatspp-icon.active {
    position: fixed;
    right: 20px;
    bottom: 20px;
  }
  .whatspp-icon .icon {
    font-size: 70px;
  }
}
