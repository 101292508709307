.mid-section img {
  width: 100%;
}
.contactbanner {
  padding-top: 0;
  position: relative;
  height: 450px;
}
.contactbanner img {
  height: 450px;
  object-fit: cover;
}
.contactbanner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 10;
}
.contactbanner .content {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  z-index: 12;
}
.contactbanner .content h4 {
  font-size: 40px;
  margin: 0;
}
.contactbanner .content p {
  font-size: 18px;
  margin: 0;
}
.contactbanner .content button {
  background-color: #000;
  margin-top: 20px;
  color: #fff;
}

@media screen and (max-width: 786px) {
  .contactbanner {
    height: 200px;
  }
  .contactbanner img {
    height: 200px;
    object-fit: cover;
  }
  .contactbanner .content {
    width: 90%;
  }
  .contactbanner .content h4 {
    font-size: 20px;
    text-align: center;
  }
  .contactbanner .content p {
    font-size: 14px;
  }
  .contactbanner .content button {
    font-size: 14px;
  }
}
