.backdrop {
  position: absolute;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  z-index: 20;
  cursor: pointer;
}
.bactive {
  left: 0;
}
