.whyus-container {
  padding: 80px 100px;
  background: linear-gradient(
    rgba(213, 247, 228, 0) 0%,
    rgb(230, 230, 230) 50%,
    rgba(255, 255, 236, 0) 100%
  );
}
.whyus {
  display: flex;
  justify-content: space-between;
  width: 100%;
  gap: 20px;
}
.why {
  width: 25%;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
}
.why p {
  font-size: 14px;
  text-align: justify;
  min-height: 150px;
}
.why .whycontent {
  display: flex;
  justify-content: space-between;
  width: 100%;
}
.why .whycontent h4 {
  font-size: 16px;
  width: 60%;
}

.why .whycontent .icon {
  background-color: #000;
  color: #fff;
  border-radius: 100px;
  width: 70px;
  height: 70px;
  padding: 2px;
}

@media screen and (max-width: 992px) {
  .whyus-container {
    padding: 50px 20px;
  }
  .whyus {
    flex-wrap: wrap;
  }
  .why {
    width: 100%;
    padding: 20px;
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 3px 8px rgba(0, 0, 0, 0.1);
  }
}
@media screen and (max-width: 600px) {
  .why p {
    font-size: 14px;
    text-align: justify;
    min-height: auto;
  }

  .why .whycontent .icon {
    width: 40px;
    height: 40px;
  }
}
