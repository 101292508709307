.before-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 50px;
}
.before-header .menus {
  display: flex;
  gap: 10px;
}
.before-header .menus li {
  cursor: pointer;
}
.before-header .search input {
  border: 1px solid var(--a);
  outline: transparent;
  padding: 8px 25px;
  border-radius: 100px;
  background-color: #f7f7f7;
}
.before-header .search input::placeholder {
  color: #999;
  font-size: 14px;
}
.before-header .logo {
  cursor: pointer;
  font-weight: bold;
}
.before-header .contact button {
  background-color: #000;
  color: #fff;
}
.header {
  background-color: var(--a);
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
}
.header .menus {
  display: flex;
  gap: 20px;
  color: #fff;
}
.header .menus li {
  cursor: pointer;
  color: #000;
  font-weight: 500;
}
@media screen and (max-width: 992px) {
  .header {
    height: 70px;
    padding: 20px;
    display: flex;
    justify-content: space-between;
  }
  .header .logo {
    font-weight: 900;
    cursor: pointer;
    color: #000;
  }
  .contact button {
    background-color: #000;
    color: #fff;
    font-size: 12px;
  }
  .header .icons {
    display: flex;
    gap: 5px;
    color: #000;
  }
  .header .icons .icon {
    cursor: pointer;
    &:hover {
      color: var(--b);
    }
  }
}
