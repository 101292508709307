.category,
.nolocation {
  padding: 80px 200px;
}
.category h2 {
  text-align: center;
  font-size: 50px;
}
.category .alllocations {
  display: flex;
  gap: 40px;
}
.category .locations {
  width: 25%;
  position: relative;
}
.category .locations img {
  width: 100%;
  height: 322px;
  object-fit: cover;
  border-radius: 15px;
  cursor: pointer;
  transition: 0.5s all ease;
}
.category .locations img:hover {
  scale: 1.01;
}
.category .locations::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(var(--b), var(--a));
  border-radius: 15px;
  transform: rotate(-3deg);
  transition: 0.5s all ease;
  z-index: -1;
}
.category .locations::after {
  content: attr(data-tourname);
  position: absolute;
  left: 0;
  bottom: 0;
  width: 100%;
  height: fit-content;
  background-color: rgba(0, 0, 0, 0.9);
  border-radius: 0 0 15px 15px;
  transition: 0.5s all ease;
  color: #fff;
  padding: 10px;
  text-align: center;
}

/* LOCATION NAME */
.locations h5 {
  position: absolute;
  bottom: 0;
}

/* NO LOCATION */
.nolocation {
  text-align: center;
  position: relative;
  overflow: hidden;
}
.nolocation h4 {
  font-size: 150px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-weight: 800;
  width: 100%;
  color: rgba(0, 0, 0, 0.1);
  color: var(--b);
  opacity: 0.2;
  z-index: -1;
}
.nolocation img {
  width: 30%;
}
/* Responsive Styles */
@media (max-width: 1200px) {
  .category {
    padding: 80px 100px;
  }
  .category h2 {
    font-size: 40px;
  }
  .category .locations {
    width: 33.33%;
  }
  .nolocation {
    padding: 50px 20px;
  }
}

@media (max-width: 992px) {
  .category {
    padding: 60px 50px;
  }
  .category h2 {
    font-size: 35px;
  }
  .category .locations {
    width: 50%;
  }
  .category .locations img {
    width: 100%;
    height: 200px;
  }
}

@media (max-width: 768px) {
  .category {
    padding: 40px 20px;
  }
  .category h2 {
    font-size: 45px;
    margin-bottom: 20px;
  }
  .category .alllocations {
    justify-content: space-between;
    flex-wrap: wrap;
    gap: 10px;
    row-gap: 25px;
  }
  .category .locations {
    width: 46%;
  }
  .nolocation h4 {
    padding: 20px;
  }
  .nolocation h4 {
    font-size: 50px;
    opacity: 0.4;
  }
  .nolocation img {
    width: 30%;
  }
}

@media (max-width: 480px) {
  .category h2 {
    font-size: 35px;
    margin-bottom: 30px;
  }
}
