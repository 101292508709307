.internation-container {
  padding: 50px;
  /* background: linear-gradient(
    rgba(213, 247, 228, 0) 0%,
    rgba(255, 255, 236, 0) 0.52%,
    rgba(255, 248, 209, 0.8) 19.27%,
    rgba(255, 248, 209, 0.8) 88.54%,
    rgba(255, 255, 236, 0) 100%
  ); */
  background: linear-gradient(
    rgba(213, 247, 228, 0) 0%,
    rgb(230, 230, 230) 50%,
    rgba(255, 255, 236, 0) 100%
  );
}
.video-container {
  width: 80%;
  display: block;
  margin: 0 auto;
  position: relative;
  height: 350px;
  overflow: hidden;
  border-radius: 20px;
}
.video-container video {
  border-radius: 15px;
}
.video-container::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 98%;
  z-index: 10;
  background-color: rgba(0, 0, 0, 0.3);
  border-radius: 15px;
}
.video-container .titles {
  position: absolute;
  top: 50px;
  text-align: center;
  width: 100%;
  color: #fff;
  z-index: 12;
}
.video-container .titles h3 {
  font-size: 40px;
  font-weight: bold;
  margin: 0;
  color: #fff;
}
.int-locations {
  width: 75%;
  display: block;
  margin: 0 auto;
  padding: 0 20px;
  margin-top: -100px;
  position: relative;
  z-index: 12;
}
.location-box {
  padding: 10px;
  cursor: pointer;
}
.location {
  position: relative;
  border-radius: 15px;
  background-color: #fff;
  overflow: hidden;
  border: 4px solid #fff;
  box-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
}
.location img {
  width: 100%;
  height: 230px;
  object-fit: cover;
  scale: 1;
  transition: 0.5s all ease;
}
.location img:hover {
  scale: 1.01;
}
.location .content {
  padding: 10px;
}
.int-buttons {
  /* position: relative; */
}
.int-buttons .left,
.int-buttons .right {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  z-index: 10;
  font-size: 40px;
  background-color: #000;
  border-radius: 100px;
  color: #fff;
  cursor: pointer;
  scale: 1;
  transition: 0.3s all ease;
  &:hover {
    scale: 1.05;
    opacity: 0.8;
  }
}
.int-buttons .left {
  left: 0px;
}
.int-buttons .right {
  right: 0px;
}
.explorebtn {
  padding: 10px 30px;
  outline: transparent;
  border: transparent;
  background-color: #000;
  color: #fff;
  border-radius: 10px;
}
