.promo-container {
  padding: 50px;
  padding-bottom: 0;
}
.promo-container img {
  width: 60%;
  display: block;
  margin: 0 auto;
}

@media screen and (max-width: 992px) {
  .promo-container {
    padding: 20px 10px;
  }
  .promo-container img {
    width: 100%;
  }
}
