.contact-container {
  background-image: url("../img/contact/contact.jpg") !important;
}
.formandtitle .address-item {
  margin-bottom: 20px;
}
.address {
  color: #fff;
  .icon {
    padding: 10px;
    font-size: 60px;
    border: 2px solid #fff;
    border-radius: 100px;
    margin-right: 10px;
    color: var(--b);
  }
}
@media screen and (max-width: 992px) {
  .formandtitle {
    height: auto;
  }
  .address {
    color: #fff;
    display: flex;
    flex-wrap: wrap;
    row-gap: 20px;
    margin-top: 100px !important;
    .icon {
      padding: 1px;
      font-size: 30px;
    }
  }
}
@media screen and (max-width: 786px) {
  .contact-container .title h4 {
    color: #000 !important;
  }

  .formandtitle .reviews.address {
    margin-top: 30px !important;
  }
  .formandtitle .reviews .address-item {
    width: 100%;
    display: flex;
    text-align: start;
  }
  .formandtitle .address-item span {
    font-size: 14px;
    text-align: start;
  }
}
