.home-form-container {
  padding: 20px 200px;
  padding-bottom: 80px;
}
.home-form-container .formm {
  background-color: var(--a);
  padding: 50px;
  border-radius: 15px;
  display: flex;
  border: 1px solid rgba(0, 0, 0, 0.1);
}
.home-form-container .form-image {
  width: 60%;
  display: flex;
  justify-content: center;
  align-items: center;
}
.home-form-container .form-image img {
  width: 80%;
}
.home-form-container .cform {
  width: 40%;
}
.home-form-container .cform span {
  color: #222;
}
.home-form-container input {
  border: 1px solid rgba(0, 0, 0, 0.3) !important;
}

@media screen and (max-width: 786px) {
  .home-form-container {
    padding: 0;
  }
  .home-form-container .formm {
    flex-wrap: wrap;
    padding: 50px 10px;
    gap: 20px;
    border-radius: 0px;
  }
  .home-form-container .form-image {
    width: 100%;
  }
  .home-form-container .form-image img {
    width: 90%;
  }
  .home-form-container .cform {
    width: 100%;
  }
}
