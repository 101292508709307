.our-team {
  position: relative;
  background: url("../img/about/team2.jpg");
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  min-height: 400px;
}
.team-box {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
}
.our-team .overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}
.our-team .team {
  width: 30%;
  background-color: #fff;
  color: #000;
  margin: 10px;
  padding: 20px;
  border-radius: 10px;
}
.our-team .team h4 {
  margin-bottom: 5px;
}
.our-team .team p {
  color: #3a3a3a;
  margin: 0;
}
.our-team .content {
  color: #fff;
  text-align: center;
  max-width: 60%;
  margin: 0 auto;
}

@media screen and (max-width: 786px) {
  .our-team {
    min-height: 600px;
  }
  .our-team .team {
    width: 100%;
  }
  .our-team .content {
    max-width: 80%;
  }
}
