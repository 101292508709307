.event-page-container {
}
.event-page-container .eventimage {
  position: relative;
}
.event-page-container .eventimage video {
  width: 100%;
  height: 500px;
  object-fit: cover;
}
.event-page-container .eventimage .bottomimage {
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
.event-page-container .eventname {
  text-align: center;
  padding: 80px 20px;
  padding-bottom: 0;
}
.event-page-container .eventname h4 {
  font-size: 50px;
  font-weight: 600;
  margin: 0;
}
.event-page-container .eventname img {
  width: 12%;
  display: block;
  margin: 0 auto;
  margin-bottom: 30px;
}
.event-page-container .eventname button {
  outline: transparent;
  border: transparent;
  background-color: #000;
  color: #fff;
  padding: 10px 15px;
  border-radius: 100px;
}

@media screen and (max-width: 786px) {
  .event-page-container .eventname h4 {
    font-size: 35px;
  }
  .event-page-container .eventname img {
    width: 50%;
  }
  .event-page-container .eventname button {
    font-size: 14px;
  }
  .event-page-container .eventimage video {
    width: 100%;
    height: 200px;
  }
}
