.roundbtn {
  border-radius: 100px;
  padding: 10px 20px;
  outline: transparent;
  border: transparent;
}
.squarebtn {
  border-radius: 10px;
  padding: 10px 20px;
  outline: transparent;
  border: transparent;
}
