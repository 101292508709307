.our-story {
  position: relative;
  /* background-image: url("../../img/home/tajmahal.jpg"); */
  background-color: var(--three);
  background-attachment: fixed;
  background-size: cover;
  background-position: center;
  padding: 80px 20px;
}
.overlay {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  /* background-color: rgba(0, 0, 0, 0.5); */
  display: flex;
  justify-content: center;
  align-items: center;
}
.content {
  /* color: #000; */
  text-align: center;
  max-width: 60%;
  margin: 0 auto;
}
.content p {
  text-align: justify;
}

@media screen and (max-width: 768px) {
  .our-story {
    padding: 50px 20px;
  }
  .content {
    max-width: 90%;
    text-align: justify;
  }
  .content h1 {
    text-align: center;
  }
}
