.form-container {
  min-height: calc(100vh - 150px);
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.steps-buttons {
  display: flex;
  justify-content: space-between;
}
.gallery-preview {
  display: flex;
  gap: 20px;
}
.gallery-img-box {
  position: relative;
  img {
    max-width: 150px;
  }
}
.gallery-img-box .icon {
  position: absolute;
  right: 5%;
  top: 10%;
  color: red;
  cursor: pointer;
}
.edit-region {
  display: flex;
  flex-direction: column;
  gap: 20px;
}
.edit-region-images {
  display: flex;
  gap: 10px;
}
.edit-region-images img {
  width: 50px;
}
