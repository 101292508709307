.search-page-container {
  padding: 80px 20px;
  min-height: 100vh;
  background: url("../img/map.webp");
  background-position: center;
  background-size: cover;
}
.search-page-container .search-container {
  width: 50%;
  display: block;
  margin: 0 auto;
  display: flex;
  align-items: flex-start;
}
.search-page-container .searchinput {
  padding: 10px;
  border: 2px solid var(--a);
  box-shadow: 0 8px 30px rgba(0, 0, 0, 0.2);
}
.search-container .icon {
  font-size: 35px;
  margin-left: -45px;
  margin-top: 5px;
}
.search-box {
  width: 50%;
  display: block;
  margin: 0 auto;
  margin-top: 20px;
  height: 350px;
  overflow-y: scroll;
}
.search-box .location-item {
  padding: 5px 10px;
  border: 1px solid rgba(0, 0, 0, 0.2);
  background-color: #fff;
  border-radius: 2px;
  font-size: 14px;
  cursor: pointer;
  display: flex;
  justify-content: space-between;
}
.search-box .location-item .icon {
  font-size: 20px;
}
.search-box .location-item:hover {
  background-color: var(--a);
  color: #fff;
  .icon {
    color: var(--b);
  }
}

@media screen and (max-width: 786px) {
  .search-page-container {
    padding: 50px 20px;
  }
  .search-page-container .search-container {
    width: 100%;
  }

  .search-box {
    width: 100%;
  }
}
